/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./header";
import "./layout.css";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <div className="h-screen">
        <main className="w-2/3 mx-auto">{children}</main>
      </div>
      <div className="flex w-full fixed bottom-0 justify-between">
        <div className="shadow-md font-semibold py-1 px-4 rounded-r-lg rounded-b-none text-gray-100 bg-gray-800 bg-opacity-40">
          Oskar Unt
        </div>
        <div className="shadow-md font-semibold py-1 px-4 rounded-l-lg rounded-b-none text-gray-100 bg-gray-800 bg-opacity-40">
          Sven Selbak
        </div>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
